const defaultState = {
  userChecked: false,
  userLogined: false,
  user: {},
  dictionaries: null,
  client: null,
  admin: false,
};

const userReducer = function (state = defaultState, action) {
  // console.log('🚀 ~ userReducer ~ action:', action);
  switch (action.type) {
    case 'USER_CHECKED':
      return Object.assign({}, state, {
        userChecked: action.value,
        userLogined: action.result.logined,
        admin: action.result.admin,
      });

    case 'SET_USER':
      return Object.assign({}, state, {
        userChecked: true,
        userLogined: true,
        user: action.user,
        admin: action.user.is_admin,
      });

    case 'UNSET_USER':
      return Object.assign({}, state, { user: {}, userLogined: false, admin: false });

    case 'SET_DICTIONARIES':
      return Object.assign({}, state, { dictionaries: action.value });

    case 'SET_CLIENT':
      return Object.assign({}, state, { client: action.value });

    default:
      return state;
  }
};

export default userReducer;
