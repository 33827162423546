import CapsApi from './CapsApi';
import { FORM_ERROR } from 'final-form';

import config from '../../config';
import store from '../../store';
import { setLoading } from '../../store/actions/commonActions';
import { getError } from '../../services/common';
import userService from '../../services/userService';
import { notify } from '../../services/common';

let lastMethod = '';

const dispatch = (action) => {
  store.dispatch(action);
};

const setLoader = (load) => {
  dispatch(setLoading(load));
  return load;
};

/**
 * gen jsonrpc packet
 * @param {*} req
 * @returns
 */
const getJSONRPCPacket = (req) => {
  req.data = {
    jsonrpc: '2.0',
    method: req.url,
    params: req.data,
  };
  req.url = '';
  return req;
};

/**
 * set auth token if needed
 * @param {*} req
 */
const setAuth = (req) => {
  lastMethod = req.data.method;
  if (req.data.method !== 'user.login') {
    //console.log('🚀 ~ setAuth ~ setAuth');
    const token = userService.getToken();
    //console.log('🚀 ~ setAuth ~ token', token);
    if (token) {
      req.headers.common['authorization'] = token;
    }
  }
  return req;
};

/**
 * intercept request
 * @param {*} req
 * @returns
 */
const prepareRequest = (req) => {
  //console.log('🚀 ~ req', req);
  setLoader(true);

  // packet
  req = getJSONRPCPacket(req);

  // auth
  req = setAuth(req);
  return req;
};

/**
 * intercept error
 * @param {*} error
 * @returns
 */
const prepareError = (error) => {
  setLoader(false);
  let msg;
  //console.log('🚀 ~ prepareerror', error);

  // response error
  // console.log('🚀 ~ prepareError ~ error:', error);
  if (error.response && error.code !== 'ERR_NETWORK') {
    msg = getError(error.response.status, 'httpErrors');
  } else {
    msg = getError(error.code, 'httpErrors', error.message);
  }
  notify('Ошибка соединения', msg);
  //console.log('🚀 ~ prepareError ~ msg', msg);

  const out = {
    [FORM_ERROR]: msg,
  };

  return Promise.reject(out);
};

/**
 * decode jsonrpc packet
 * @param {*} res
 * @returns
 */
const decodeJSONRPCPacket = (res) => {
  let out = {};
  let error = false;

  // no auth
  //console.log('🚀 ~ decodeJSONRPCPacket ~ res.status', lastMethod);
  if (res.status === 403 && lastMethod !== 'user.login') {
    // 401?
    userService.logout();
    return { packet: {}, error: true };
  }

  const data = res.data;
  //console.log('🚀 ~ decodeJSONRPCPacket ~ res', res);

  if (data.error) {
    if (data.error.code === 403 || data.error.code === -32603) {
      if (userService.isLogined()) {
        userService.logout();
        return { packet: {}, error: true };
      }
    }
    if (data.error.code === -32601) {
      notify('Ошибка API', JSON.stringify(data.error));
    }
    let msg = data.error.message;
    //console.log('🚀 ~ decodeJSONRPCPacket ~ error', error);
    if (msg.indexOf(': ') !== -1) {
      msg = msg.substr(msg.indexOf(':') + 2);
    }
      if (data.error.message === 'Item not found') {
      out[FORM_ERROR] = getError(1600, 'errors', data.error.message);
    } else {
      out[FORM_ERROR] = msg; //getError(data.error.code, 'errors', data.error.message);
    }
    
    error = true;
  } else {
    out = data.result;
  }

  return { packet: out, error };
};

/**
 * intercept respose
 * @param {*} res
 * @returns
 */
const prepareResponse = (res) => {
  //console.log('🚀 ~ prepareResponse ~ res', res);
  setLoader(false);
  const out = decodeJSONRPCPacket(res);
  //console.log('🚀 ~ prepareResponse ~ out', out);

  if (out.error) {
    return Promise.reject(out.packet);
  }
  return out.packet;
};

const api = new CapsApi({ apiUrl: config.networkConfig.apiUrl, prepareRequest, prepareResponse, prepareError });

export default api;
