import projectInfo from '../package.json';
import meta from './metadata.json';

// constants
window.noApi = true;
window.BSTR_VERSION = projectInfo.version;
window.BSTR_BUILD = meta.build;

// test settings
const test =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'test' ||
  process.env.REACT_APP_DEV === '1' ||
  window.location.href.indexOf('localhost') !== -1;
if (test) {
  window.config.debug = true;
  if (window.location.href.indexOf('localhost') !== -1) {
    window.config.localhost = true;
    // window.config.networkConfig.apiUrl = 'http://10.15.252.234:38086/api';
    // window.config.networkConfig.apiUrl = 'http://10.15.252.234:3082/api';
    // window.config.networkConfig.apiUrl = 'http://10.105.0.127:81/api';
    
    // * stage
    window.config.networkConfig.apiUrl = 'https://lk.stockmann.stage.caps.smprc.ru/api';
    
    // * work
    // window.config.networkConfig.apiUrl = 'http://10.105.0.127:8086/api';

    // mens
    // window.config.networkConfig.apiUrl = 'http://10.1.164.201:81/api';
    // window.config.networkConfig.xlsUrl = 'http://10.105.0.127:8086';

    // window.config.networkConfig.apiUrl = 'http://10.105.0.127:81/api';
    // window.config.networkConfig.xlsUrl = 'http://10.105.0.127:81';
  }

  console.log('env: test');
  console.log('API url: ' + window.config.networkConfig.apiUrl);
}

const configAdd = {
  defaultPageSize: 10,
  pageSizes: [10, 20, 50, 100],
  defaultClientPath: '/orders',
  defaultAdminPath: '/orders',
  clientMenu: [
    {
      title: 'Заявки',
      link: '/orders',
      icon: 'supervisor_account',
      otherItems: ['/orders-add', '/order/'],
    }, //'directions_bus'
    {
      title: 'Платежи',
      link: '/payments',
      icon: 'directions_bus',
    }, //'directions_bus'
    {
      title: 'Валидация',
      link: '/validator',
      icon: 'directions_bus',
    }, //'directions_bus'
    {
      title: 'Льготные платежи',
      link: '/repayments',
      icon: 'directions_bus',
      //otherItems: ['/orderadd', '/order/'],
    }, //'directions_bus'
    //{title: '(!) Генератор', link: '/generator', icon: ''}
  ],
  adminMenu: [
    {
      title: 'Заявки',
      link: '/orders',
      icon: 'supervisor_account',
      otherItems: ['/order/'],
    },
    {
      title: 'Платежи',
      link: '/payments',
      icon: 'directions_bus',
    },
    {
      title: 'Льготные платежи',
      link: '/repayments',
      icon: 'directions_bus',
    },
    {
      title: 'Скидки ',
      link: '/benefits',
      icon: 'directions_bus',
    },
  ],
  localeConfig: {
    viewDateFormat: 'D MMM YYYY',
    viewDateFormatWithTime: 'D MMM YYYY HH:mm',
    workDateFormat: 'YYYY-MM-DD',
  },
  cookieConfig: {
    domain: window.location.host,
  },
  httpErrors: {
    ERR_NETWORK: { ru: 'Ошибка соединения' },
    ECONNABORTED: { ru: 'Соединение прекращено' },
    597: { ru: 'Неверный старый пароль' },
    598: { ru: 'При импорте возникли ошибки' },
    599: { ru: 'Неизвестная ошибка сервера' },
    400: { ru: 'Ошибка данных' },
    401: { ru: 'Неправильный логин или пароль' },
    403: { ru: 'Нет прав для выполнения операции' },
    404: { ru: 'Страница не найдена' },
    423: { ru: 'Данный файл уже импортировался' },
    500: { ru: 'Ошибка сервера' },
    600: { ru: 'Недостаточно средств на счету' },
  },
  errors: {
    '-32602': { ru: 'Пользователь не найден', en: 'Unknown user' },
    0: { ru: 'Неизвестная ошибка', en: 'Unknown error' },
    7: {
      ru: 'Код города/оператора должен начинаться с цифры 3, 4, 5, 6, 8, 9',
      en: 'The city/operator code must start with a digit 3, 4, 5, 6, 8, 9',
    },
    8: {
      ru: 'Телефонный номер должен состоять из 10 цифр',
      en: 'The phone number must consist of 10 digits',
    },
    9: { ru: 'Укажите ваше ФИО', en: 'Empty name' },
    10: {
      ru: 'Недостаточно информации. Введите фамилию, имя и отчество через пробел (Например: Иванов Иван Алексеевич)',
      en: 'Insufficient information. Enter the last name, first name and patronymic separated by a space (for Example: Ivanov Ivan Alekseevich',
    },
    11: {
      ru: 'Допустимо использовать только буквы русского, латинского алфавита и дефис',
      en: 'It is permissible to use only the Cyrillic, the Latin alphabet and the hyphen',
    },

    12: { ru: 'Укажите дату рождения', en: 'Empty birth date' },
    13: {
      ru: 'Вам должно быть от 18 до 100 лет',
      en: 'You must be between 18 and 100 years old',
    },
    14: {
      ru: 'Дата должна быть не ранее {last100years}',
      en: 'The date must not be earlier than {last100years}',
    },
    15: { ru: 'Укажите корректную дату', en: 'Enter the correct date' },

    16: { ru: 'Введите 6 цифр', en: 'Enter 6 digits' },
    17: { ru: 'Введите 10 цифр', en: 'Enter 10 digits' },
    18: { ru: 'Введите 20 цифр', en: 'Enter 20 digits' },
    19: { ru: 'Введите 13 цифр', en: 'Enter 13 digits' },
    20: { ru: 'Введите 9 цифр', en: 'Enter 9 digits' },

    21: {
      ru: 'введите Бик существующего банка',
      en: 'Enter the BIC of an existing Bank',
    },
    22: {
      ru: 'БИК должен начинаться с цифр "04"',
      en: 'The BIC must start with the digits "04"',
    },

    23: { ru: 'Введите 12 цифр', en: 'Enter 12 digits' },
    24: { ru: 'Введите наименование', en: 'Empty name' },
    25: { ru: '', en: '' },
    26: {
      ru: 'Выбранная дата не может быть раньше сегодняшней ',
      en: 'Selected date cannot be earlier than today',
    },
    27: {
      ru: 'Дата активации при изменении активного тарифа не может быть текущей ',
      en: 'Activating date cannot be earlier than tomorrow',
    },
    28: { ru: 'Неверный формат ГРЗ', en: 'Bad license plate format' },
    29: { ru: 'ГРЗ привязан', en: 'Bad already connected' },

    101: { ru: 'Ошибка сервера', en: 'Internal error' },
    //200: { ru: 'Недостаточно средств на счету для создания заявки. Пополните счёт или уменьшите период', en: 'Missing funds to hold' },
    202: { ru: 'Введен неверный логин или пароль', en: 'Bad user' },
    205: { ru: 'Клиент не найден', en: 'Client not found' },
    230: { ru: 'Введите корректный адрес эл. почты', en: 'Invalid email' },
    231: { ru: 'Введите корректный телефон', en: 'Invalid phone' },
    232: { ru: 'Поле заполнено некорректно', en: 'Invalid value' },
    233: { ru: 'Неверный тип', en: 'Invalid type' },
    238: {
      ru: 'Пароль должен содержать не менее 1 символа',
      en: 'Invalid text for password',
    },
    247: { ru: 'Неверный PAN или PIN', en: 'Invalid PAN or PIN' },
    260: { ru: 'Пароли не совпадают', en: "Passwords aren't match" },
    270: { ru: 'Введите значение больше 0', en: "Value can't be zero" },  
    400: { ru: 'Ошибка данных' },
    401: { ru: 'Неправильный логин или пароль' },
    403: { ru: 'Ошибка логина или пароля' }, //Нет прав для выполнения операции
    404: { ru: 'Страница не найдена' },
    423: { ru: 'Данный файл уже импортировался' },
    500: { ru: 'Ошибка сервера' },
    600: { ru: 'Недостаточно средств на счету' },
    1600: { ru: 'Парковка не найдена' },
  },
};

window.config = { ...window.config, ...configAdd };
if (!window.config.consts) {
  window.config.consts = {
    paymentType: {
      income: 'Зачисление',
      outcome: 'Списание',
    },
    orderStatus: {
      created: 'Новая',
      active: 'Активна',
      finished: 'Завершена',
      archive: 'Архивирована',
    },
  };
}

console.log('version: ' + projectInfo.version + ' / ' + meta.build);

export default window.config;
