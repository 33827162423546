import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';

// layouts
const Layout = lazy(() => import('../components/layout/Layout'));

export const PublicRoute = ({ component: Component, userLogined, ...rest }) => {
  const linkOld = window.continueLink;
  if (
    userLogined &&
    linkOld /* && (['/confirmEmail', '/restorepass', '/registration', '/prefill'].indexOf(rest.path) === -1)*/
  ) {
    window.continueLink = '';
    const nextUrl = linkOld;
    // console.log(' >> ' + nextUrl);
    return <Redirect to={nextUrl} />;
  } else {
    // console.log('public component at ' + rest.path);
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
};
PublicRoute.propTypes = {
  component: PropTypes.elementType,
  userLogined: PropTypes.bool.isRequired,
};
