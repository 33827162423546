import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';

// layouts
const Layout = lazy(() => import('../components/layout/Layout'));
const LayoutBackend = lazy(() => import('../components/layout/LayoutBackend'));

export const PrivateRoute = ({ component: Component, userLogined, theme, admin, ...rest }) => {
  //console.log('🚀 ~ PrivateRoute ~ admin', admin);
  if (userLogined) {
    // console.log('private component at ' + rest.path);
    if (theme) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Layout theme={theme}>
              <Component {...props} admin={admin} />
            </Layout>
          )}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          <LayoutBackend theme={theme}>
            <Component {...props} admin={admin} />
          </LayoutBackend>
        )}
      />
    );
  } else {
    // console.log(' >> /login');
    if (!window.continueLinkAfterLogout) {
      window.continueLink = history.location;
    }
    window.continueLinkAfterLogout = false;
    const to = '/login';

    return <Redirect to={to} />;
  }
};

PrivateRoute.defaultProps = {
  admin: false,
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  userLogined: PropTypes.bool.isRequired,
  admin: PropTypes.bool,
  theme: PropTypes.string,
};
