import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router';

import history from '../services/history';
import Loader from '../components/reusable/Loader';
// system
const NotFound = lazy(() => import('../components/errors/404'));
const ErrorPage = lazy(() => import('../components/errors/500'));
const VersionError = lazy(() => import('../components/errors/VersionError'));
// test
const Testing = lazy(() => import('../components/testing/Testing'));
// login registration restore
const Login = lazy(() => import('../components/login/Login'));
// orders
const Orders = lazy(() => import('../components/orders/Orders'));
const Order = lazy(() => import('../components/orders/Order'));
const OrderAdd = lazy(() => import('../components/orders/OrderAdd'));
const Validator = lazy(() => import('../components/validator/validator'));
const RePayments = lazy(() => import('../components/repayments/RePayments'));
// payments
const Payments = lazy(() => import('../components/payments/Payments'));

// benefits
const Benefits = lazy(() => import('../components/benefits/Benefits'));

const QR = lazy(() => import('../components/qr/qr'));
const PDF = lazy(() => import('../components/pdf/pdf'));
// admin
/*
const LoginAdmin = lazy(() => import('../components/login/LoginAdmin'));
const OrdersAdmin = lazy(() => import('../components/orders/OrdersAdmin'));
const OrderAdmin = lazy(() => import('../components/orders/OrderAdmin'));
const PaymentsAdmin = lazy(() => import('../components/payments/PaymentsAdmin'));
const RePaymentsAdmin = lazy(() => import('../components/repayments/RePaymentsAdmin'));
*/

// routes
import { PrivateRoute } from './private-route';
import { PublicRoute } from './public-route';

const AppRouter = (props) => {
  const { admin, userLogined } = props;
  // console.log('🚀 ~ AppRouter ~ userLogined:', userLogined, admin);
  //console.log('render routes');
  return (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <Switch>
          {/*public routes*/}
          <Route path="/versionerror" component={VersionError} />
          <Route path="/testing" component={Testing} />
          <Route path="/qr/:id" component={QR} />
          <Route path="/pdf/:id" component={PDF} />

          <PublicRoute exact path="/" component={Login} userLogined={userLogined} />
          <PublicRoute path="/login" component={Login} userLogined={userLogined} />

          {/*<PublicRoute path="/admin/login" component={LoginAdmin} userLogined={userLogined} />*/}

          <Route path="/error" render={(props) => <ErrorPage {...props} />} />

          {/*private routes*/}
          <PrivateRoute path="/error" component={ErrorPage} userLogined={userLogined} />

          <PrivateRoute path="/orders" component={Orders} userLogined={userLogined} admin={admin} />
          <PrivateRoute path="/orders-add" component={OrderAdd} userLogined={userLogined} admin={admin} />
          <PrivateRoute path="/order/:id" component={Order} userLogined={userLogined} admin={admin} />

          <PrivateRoute path="/payments" component={Payments} userLogined={userLogined} admin={admin} />

          <PrivateRoute path="/validator" component={Validator} userLogined={userLogined} admin={admin} />

          <PrivateRoute path="/repayments" component={RePayments} userLogined={userLogined} admin={admin} />

          <PrivateRoute path="/benefits" component={Benefits} userLogined={userLogined} admin={admin} />          

          <PrivateRoute path="*" component={NotFound} userLogined={userLogined} />
        </Switch>
      </Suspense>
    </Router>
  );
};

AppRouter.propTypes = {
  userLogined: PropTypes.bool.isRequired,
  admin: PropTypes.bool,
};

AppRouter.defaultProps = {
  userLogined: false,
  admin: false,
};

export default AppRouter;
