import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');
moment.suppressDeprecationWarnings = true;
import { Store } from 'react-notifications-component';
import { FORM_ERROR } from 'final-form';
import printJS from 'print-js';

import config from '../config';
import history from './history';
import lang from './lang';

const consts = window.config.consts;
const workDateFormat = window.config.localeConfig.workDateFormat;
const viewDateFormat = window.config.localeConfig.viewDateFormat;

/**
 * date time functions
 */
export const stringToDate = (time, format = 'D MMMM YYYY') => {
  const out = moment(time);
  out.locale('ru');
  return out.format(format);
};

export const unixToDate = (time, format = 'D MMMM YYYY') => {
  const out = moment.unix(Math.round(time / 1000000));
  out.locale('ru');
  return out.format(format);
};

export const momentFromServer = (date, fromFormat = workDateFormat) => {
  if (!fromFormat) {
    return moment.utc(date);
  }
  return moment.utc(date, fromFormat, true);
};

export const dateFromServer = (date, fromFormat = workDateFormat, toFormat = viewDateFormat) => {
  if (!fromFormat) {
    return moment(date).format(toFormat);
  }
  return moment(date, fromFormat, true).format(toFormat);
};

export const dateToServer = (date, toFormat = workDateFormat) => {
  if (!date) {
    return date;
  }
  return moment(date).format(toFormat);
};

export const formatDate = (date, format = 'DD.MM.YYYY|HH:mm:ss') => {
  if (!date) {
    return '';
  }

  let d = dateFromServer(date, null, format);
  //console.log('🚀 ~ formatDate ~ format', format);
  d = d.split('|');
  let dateString = (
    <>
      {d[0]} <i>{d[1]}</i>
    </>
  );

  return dateString;
};

/**
 * file functions
 */

/**
 * read file from form
 *
 * @param {*} file
 * @returns
 * @memberof Client
 */
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(',').pop());
      //resolve(new Uint8Array(reader.result));
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const readFile = async (file) => {
  //console.log('read file' + file.name)
  let data;
  try {
    data = await readFileAsync(file);
    //console.log('read file ok', data)
  } catch (error) {
    notify('readFile error', error);
  }
  //console.log('read file end')
  return data;
};

export const downloadFile = (link, blank = false) => {
  /*
    const blob = new Blob([data], { type: 'text/html' });
    */
  //link.href = window.URL.createObjectURL(blob);
  //let fn = link.split('/');
  //fn = fn[fn.length - 1];
  const el = window.document.createElement('a');
  el.href = link;
  el.download = true; //fn;
  if (blank) {
    el.target = '_blank';
  }
  window.document.body.appendChild(el);
  el.click();
  window.document.body.removeChild(el);
};

export const printFile = (link) => {
  if (!link) {
    return;
  }
  printJS(link);
};

/**
 * unused
 */

export const objectFilter = (object, allow) => {
  return Object.keys(object)
    .filter((key) => allow.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

export const useMergeState = (initialState) => {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState) => setState((prevState) => Object.assign({}, prevState, newState));
  return [state, setMergedState];
};

/**
 * other functions
 */

export const equalObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const notify = (title, message, type = 'danger', insert = 'top', container = 'top-right', dismiss = 3000) => {
  //console.log(title)
  if (!title) {
    title = 'Error';
  }

  if (typeof title === 'object') {
    title = title.message;
  }

  if (typeof message !== 'string') {
    message = JSON.stringify(message);
  }

  if (!message) {
    message = 'Неизвестная ошибка';
  }

  const params = {
    title: title,
    message: message,
    type: type,
    insert: insert,
    container: container,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    //dismiss: { duration: 2000 },
    dismissable: { click: true },
  };

  if (dismiss) {
    params.dismiss = { duration: dismiss, onScreen: true };
  }

  //console.log('notify', params);
  Store.addNotification(params);
};

export const fakeWait = (counts) => {
  let k;
  const cnt = counts * 100000;
  for (let i = 0; i < cnt; i++) {
    k = i ** 9;
  }
  return k;
};

export const wait = (time) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(time), time);
  });

export const route = (url) => {
  // console.log('route: ' + url);
  history.push(url);
};

/**
 * return error string by the error code
 *
 * @param {*} code
 */
export const getError = (code, place = 'errors', addMessage = '') => {
  //console.log('🚀 ~ getError ~ code:', code);
  //code = parseInt(code);
  const codeOriginal = code;
  if (!config[place][codeOriginal]) {
    code = 0;
  }
  let error = config[place][code][lang];
  if (code === 0) {
    error += ' (' + codeOriginal + ' ' + addMessage + ')';
  }
  return error;
};

/**
 * get errors from api or local
 *
 * type error {"code":101,"message":"phone and email are not set","data":[],"file_name":"","line_number":"unknown","function_name":"userValidate_case__registration"}
 *
 * @param {*} error
 */
export const getServerErrors = (errors, code = -1) => {
  const systemErrors = ['merchant_key', 'detail'];

  // console.log('getServerErrors', errors, code);
  let isString = typeof errors === 'string';
  let out = {};

  if (code !== -1) {
    if (isString) {
      if (errors.indexOf('Not enough money') !== -1) {
        code = 600;
      } else {
        code = 597;
      }
    }

    if (Array.isArray(errors)) {
      if (typeof errors[0] === 'string' && errors[0].indexOf('Not enough money') !== -1) {
        errors = errors[0];
        isString = true;
        code = 600;
      } else {
        code = 598;
      }
    }
    if (!window.config.httpErrors[code]) {
      code = 599;
    }

    out[FORM_ERROR] = getError(code, 'httpErrors');
  }

  if (!isString) {
    if (Array.isArray(errors)) {
      out.errors = errors;
    } else {
      Object.keys(errors).forEach((el) => {
        let code = el;
        if (systemErrors.indexOf(el) !== -1) {
          code = FORM_ERROR;
        }
        out[code] = Array.isArray(errors[el]) ? errors[el].join(', ') : errors[el];
      });
    }
  }

  // console.log(out);

  return out;
};

/**
 * get errors from local
 *
 * @param {*} errors {fn: code, ...}
 */
export const getLocalErrors = (errors) => {
  //console.log(`!!! getLocalErrors`, errors);
  const out = {};

  if (!errors) {
    return out;
  }

  Object.keys(errors).forEach((fn) => {
    const error = errors[fn];
    if (fn === FORM_ERROR) {
      out[fn] = error;
    } else {
      const code = fn === 0 ? FORM_ERROR : fn;
      out[code] = getError(error);
    }
  });

  return out;
};

export const isEmptyObject = (val) => {
  if (typeof val !== 'object') {
    return true;
  }
  return Object.keys(val).length === 0;
};

/**
 * format number value
 *
 * @param {*} number
 * @param {*} decimals
 * @param {*} dec_point
 * @param {*} thousands_sep
 * @returns
 */
export const numberFormat = (number, { decimals = 0, dec_point = '.', thousands_sep = ' ', toValute = false }) => {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  if (!toValute) {
    return s.join(dec);
  }
  const other = s[1] ? ',' + s[1] : '';

  return (
    <>
      {s[0]}
      <span>{other} ₽</span>
    </>
  );
};

/**
 * format valute value
 * @param {*} n
 * @param {*} sign
 * @returns
 */
export const numberToValute = (n, sign = '', withValute = true, withoutDecimals = true) => {
  let s = n.toString().replace(',', '.').split('.');
  if (!s[1] && !withoutDecimals) {
    s.push('00');
  }
  const valute = withValute ? ' ₽' : '';
  const other = s[1] ? ',' + s[1] : '';

  return (
    <>
      {sign}
      {s[0]}
      <span>
        {other}
        {valute}
      </span>
    </>
  );
};

/**
 * get short pan value
 * @param {*} pan
 * @returns
 */
export const getLabel = (pan) => {
  let out = pan.toString();
  if (out) {
    out = out.substring(out.length - 9);
  }
  return out;
};

/**
 * format pan value
 * @param {*} pan
 * @returns
 */
export const formatLabel = (pan) => {
  let out = getLabel(pan);

  const v = out.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    out = parts.join(' ');
  }

  return out;
};

/**
 * convert linear date to array
 *
 * @param {*} data linear form data {field1__1, field2__1, ...}
 */
export const reduceFormData = (data) => {
  const out = {};
  Object.keys(data).forEach((key) => {
    let key1 = key.split('__');
    if (!out[key1[1]]) {
      out[key1[1]] = {};
    }
    out[key1[1]][key1[0]] = data[key];
  });

  return Object.keys(out).map((key) => {
    return out[key];
  });
};

/**
 * set global form error for final form
 * @param {*} errors
 * @param {*} error
 */
export const getGlobalFormError = (errors, error) => {
  errors[FORM_ERROR] = error;
};

/**
 * convert order from asc/desc to python django format
 * @param {*} order
 * @returns
 */
export const convertOrder = (order) => {
  let out = order;
  let s = order.split(' ');
  if (s.length > 1) {
    let sign = s[1].trim() === 'asc' ? '' : '-';
    out = sign + s[0].trim();
  }

  return out;
};

export const getObjectField = (object, field) => {
  if (!object) {
    object = {};
  }
  return object[field] ? object[field] : '';
};

export const getCarName = (vehicle) => {
  return (getObjectField(vehicle, 'name') + ' ' + getObjectField(vehicle, 'model_car')).trim();
};

export const preparePan = (v) => {
  return v.replace(/\s/g, '');
};

export const isObject = (v) => {
  return typeof v === 'object' && v !== null;
};

export const loadRecaptcha = () => {
  // load the script by passing the URL

  loadScriptByURL(
    'recaptcha-key',
    `https://www.google.com/recaptcha/api.js?render=${window.config.recaptchakey}`,
    function () {
      console.log('recaptcha script loaded!');
    }
  );
};

const loadScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export const waitRecaptcha = async () => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(window.config.recaptchakey, { action: 'login' })
        .then((token) => {
          resolve(token);
        })
        .catch((result) => {
          reject(result);
        });
    });
  });
};

export const getCarClass = (row) => {
  let out = row.vehicle_class_in;
  if (row.vehicle_class_out && row.vehicle_class_out !== row.vehicle_class_in) {
    out += '/' + row.vehicle_class_out;
  }
  return out;
};

export const getCarClass2 = (item, BigCmp, type = 'pre') => {
  const labelClass = item.label ? item.label.tariff_group : 'x';
  const name0 = 'vehicle_class_' + type;
  const nameIn = name0 + '_in';
  const nameOut = name0 + '_out';
  return (
    <>
      {item[nameIn] ? item[nameIn] : 'x'}-<BigCmp>{labelClass}</BigCmp>-{item[nameOut] ? item[nameOut] : 'x'}
    </>
  );
};

export const getSumm = (row) => {
  const sum = row.sum;
  let formattedSum = '';
  const minus = [consts.opTypes.JOURNEY, consts.opTypes.ISSUE_A_LABEL].includes(row.operation_type);
  const cl = sum < 0 || minus ? 'minus' : 'plus';
  let s1 = numberFormat(sum, { decimals: 2, dec_point: '.' });
  s1 = s1.split('.');
  if (minus) {
    if (sum && s1[0].indexOf('-') === -1) {
      formattedSum += '-';
    }
  } else {
    if (sum >= 0) {
      formattedSum += '+';
    }
  }

  formattedSum += s1[0];
  return { summ1: formattedSum, summ2: s1[1], cl };
};

export const momentToServer = (d) => {
  return moment(d).toISOString();
};

/**
 * get client name from client object
 *
 * @param {*} client
 * @returns
 */
export const getClientName = (client) => {
  let out = '';

  if (client && client.name) {
    out = client.name;
  } else {
    out = 'Администратор';
  }

  if (!out.trim()) {
    out = 'Пользователь';
  }

  return out;
};

/**
 * get client balance
 * @param {*} client
 * @returns
 */
export const getClientBalance = (client) => {
  let out = '';

  if (client && client.data && client.account) {
    out = client.data.unlimited ? '' : client.account.balance / 100;
  }

  if (typeof out === 'number') {
    out = numberFormat(out, { decimals: 0, toValute: true });
  }

  return out;
};

/**
 * get obj and list from consts
 * @param {*} name
 * @returns
 */
export const getConstList = (name, params = { withAll: true }) => {
  const obj = consts[name];
  const list = Object.keys(obj).map((item) => ({ value: item, title: obj[item] }));
  if (params.withAll) {
    list.splice(0, 0, { value: '-1', title: 'Все' });
  }

  return { obj, list };
};

/**
 * get obj and list from consts
 * @param {*} name
 * @returns
 */
export const getSettingsList = (name, data, params = { withAll: false }) => {
  const items = data[name];
  const obj = {};
  if (!items) {
    return { obj, list: [] };
  }
  const list = items.map((item) => {
    obj[item.name] = item.display_name;
    return { value: item.name, title: item.display_name };
  });
  if (params.withAll) {
    list.splice(0, 0, { value: '-1', title: 'Все' });
  }

  return { obj, list };
};

/**
 * change plate letters from rus to en
 * @param {*} plate
 */
export const preparePlate = (plate) => {
  // const tr = {
  //   A: 'А',
  //   B: 'В',
  //   E: 'Е',
  //   K: 'К',
  //   M: 'М',
  //   H: 'Н',
  //   O: 'О',
  //   P: 'Р',
  //   C: 'С',
  //   T: 'Т',
  //   Y: 'У',
  //   X: 'Х',
  // };
  const tr = {
    А: 'A',
    В: 'B',
    Е: 'E',
    К: 'K',
    М: 'M',
    Н: 'H',
    О: 'O',
    Р: 'P',
    С: 'C',
    Т: 'T',
    У: 'Y',
    Х: 'X',
  };

  let plateOk = '';
  plate
    .trim()
    .toUpperCase()
    .split('')
    .forEach((v) => {
      if (tr[v]) {
        v = tr[v];
      }
      plateOk += v;
    });

  return plateOk;
};
