import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Router from '../router/router';
import userService from '../services/userService';

class App extends React.Component {
  componentDidMount() {
    // check user token
    userService.checkToken();
  }

  render() {
    const { admin, userLogined, userChecked } = this.props;

    // if not user checked not render
    if (!userChecked) {
      return null;
    }

    // render router
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
        <Router userLogined={userLogined} admin={admin} />
      </MuiPickersUtilsProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userLogined: PropTypes.bool,
  userChecked: PropTypes.bool,
  admin: PropTypes.bool,
};

const mapStateToProps = function (store) {
  return {
    userChecked: store.user.userChecked, // user token checked
    userLogined: store.user.userLogined, // user ready for backend
    admin: store.user.admin, 
  };
};

export default connect(mapStateToProps)(App);
